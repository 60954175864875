import React from 'react';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

interface ProductProps {
  product: {
    id: any;
    name: any;
    image: string;
    price: number;
    quantity: string;
  };
}

const Product: React.FC<ProductProps> = ({ product }: any) => {
  const whatsappNumber = '9346343940'; // Replace with actual WhatsApp number
  const phoneNumber = '9346343940'; // Replace with actual phone number

  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col">
      {/* Product Image */}
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-60 object-cover rounded-t-lg"
      />

      {/* Product Details */}
      <div className="p-4 flex flex-col justify-between flex-grow">
        <h2 className="text-lg font-semibold text-gray-800">{product.name}</h2>
        <p className="text-gray-600 text-sm">{product.quantity}</p>
        <p className="text-green-600 text-lg font-bold mt-2">₹{product.price}</p>

        {/* Buttons */}
        <div className="mt-4 flex space-x-4">
          {/* Order Button */}
          <a
            href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
              `Hi, I'd like to order ${product.name} (${product.quantity}) for ₹${product.price}.`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-green-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-green-600 flex-1"
          >
            <FaWhatsapp className="mr-2 text-lg" /> Order
          </a>

          {/* Call Button */}
          <a
            href={`tel:${phoneNumber}`}
            className="flex items-center justify-center bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 flex-1"
          >
            <FaPhoneAlt className="mr-2 text-lg" /> Call
          </a>
        </div>
      </div>
    </div>
  );
};

export default Product;
