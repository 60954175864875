import React from 'react';
import { FaLeaf, FaHandsHelping, FaShieldAlt, FaUserCheck } from 'react-icons/fa'; // Importing icons

const AboutScreen = () => {
  return (
    <div className="bg-gray-50 min-h-screen text-gray-700">
      {/* Hero Section */}
      <section className="bg-green-500 text-white py-16">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-4xl font-semibold mb-4 sm:text-3xl md:text-4xl">About Green Cuts</h1>
          <p className="text-lg max-w-3xl mx-auto sm:text-base">
            At Green Cuts, we believe in delivering the freshest, healthiest chopped vegetables directly to your doorstep within minutes. Our team is driven by quality, convenience, and sustainability to ensure every meal is as fresh as possible.
          </p>
        </div>
      </section>

      {/* Trust and Expertise Section */}
      <section className="container mx-auto py-16 px-4 md:px-0">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-semibold mb-4 sm:text-2xl md:text-3xl">Why Trust Us?</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto sm:text-base">
            Our team consists of experienced professionals who are passionate about providing you with fresh, healthy, and top-quality vegetables. With years of industry expertise, we are committed to serving you better every day.
          </p>
        </div>

        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center flex flex-col items-center">
            <FaLeaf className="text-green-500 text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Our Expertise</h3>
            <p>
              Our team of experts brings years of experience in sourcing, cutting, and delivering high-quality vegetables. We use the latest technology to ensure freshness and hygiene.
            </p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center flex flex-col items-center">
            <FaHandsHelping className="text-green-500 text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Sustainability Commitment</h3>
            <p>
              We prioritize sustainability by minimizing waste and using eco-friendly packaging. Our delivery methods are designed to be environmentally conscious while delivering your fresh veggies promptly.
            </p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center flex flex-col items-center">
            <FaShieldAlt className="text-green-500 text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Customer Satisfaction</h3>
            <p>
              We are committed to your satisfaction. Our dedicated customer support team ensures a seamless experience, from ordering to delivery. We always put our customers first.
            </p>
          </div>
        </div>
      </section>

      {/* Team and Credentials Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-semibold mb-8 sm:text-2xl md:text-3xl">Our Team & Credentials</h2>
          <p className="text-lg max-w-3xl mx-auto mb-6 sm:text-base">
            Our team combines expertise in agriculture, food safety, logistics, and technology to bring the best service to you. We are also continuously expanding our knowledge to innovate and provide even better services.
          </p>

          {/* Flex container with 2 cards on desktop and stacked on mobile */}
          <div className="flex flex-col md:flex-row gap-2 justify-center sm:flex-col md:grid md:grid-cols-3">
            <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg w-full sm:w-full flex flex-col items-center">
              <FaUserCheck className="text-white text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-4">Certified & Experienced</h3>
              <p className="text-center">Our team members are certified in food safety standards and logistics, ensuring top-tier services.</p>
            </div>

            <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg w-full sm:w-full flex flex-col items-center">
              <FaHandsHelping className="text-white text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-4">Customer-Centric</h3>
              <p className="text-center">With years of experience in customer service, we ensure smooth communication and satisfaction with every order.</p>
            </div>
            <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg w-full sm:w-full flex flex-col items-center">
              <FaHandsHelping className="text-white text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-4">Time Saving</h3>
              <p className="text-center">In just one clcik, you can order, so you can save lot of time for cutting vrgitables.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-green-500 text-white py-16 text-center px-4 mb-1">
        <h2 className="text-3xl font-semibold mb-4 sm:text-2xl md:text-3xl">Get Started with Fresh Chops Today</h2>
        <p className="text-lg mb-6 sm:text-base">Experience the difference of freshly chopped vegetables delivered directly to your doorstep. Order now and enjoy a healthier lifestyle!</p>
        <a
          href="/products"
          className="bg-white text-green-500 font-semibold py-2 px-6 rounded-lg hover:bg-gray-200"
        >
          Order Now
        </a>
      </section>
    </div>
  );
};

export default AboutScreen;
