import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-green-500 text-white py-10">
      <div className="container mx-auto px-4 grid md:grid-cols-3 gap-8">
        {/* Brand and About Section */}
        <div>
          <div className="flex items-center mb-4">
            <img src="/logos/main_logo.png" alt="Green Cuts Logo" className="w-12 h-12" /> {/* Your Logo */}
            <span className="ml-3 text-lg font-bold text-white">Green Cuts</span>
          </div>
          <p className="text-gray-100">
            Fresh Chops delivers premium-quality, freshly chopped vegetables to your doorstep within minutes. Save time, eat fresh!
          </p>
          {/* Social Media Icons */}
          <div className="flex space-x-4 mt-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="p-2 bg-white text-green-500 rounded-full hover:bg-gray-200">
              <FaFacebookF size={16} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="p-2 bg-white text-green-500 rounded-full hover:bg-gray-200">
              <FaInstagram size={16} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="p-2 bg-white text-green-500 rounded-full hover:bg-gray-200">
              <FaTwitter size={16} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="p-2 bg-white text-green-500 rounded-full hover:bg-gray-200">
              <FaLinkedinIn size={16} />
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2">
            <li><a href="#home" className="hover:underline">Home</a></li>
            <li><a href="#products" className="hover:underline">Products</a></li>
            <li><a href="#about" className="hover:underline">About</a></li>
            <li><a href="#contact" className="hover:underline">Contact</a></li>
            <li><a href="#community" className="hover:underline">Community</a></li>
          </ul>
        </div>

        {/* Contact Info */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Get in Touch</h3>
          <ul className="space-y-2">
            <li className="flex items-center">
              <span className="mr-2">📧</span>
              <span>contact@greencuts.in</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">📞</span>
              <span>+91 9346343940</span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">📍</span>
              <span>Nacharam, Hyderabad City</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-8 text-center text-sm text-gray-200">
        © 2024 Green Cuts. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
