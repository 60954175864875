import React, { useState } from 'react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white fixed w-full shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Brand Name */}
        <div className="flex items-center">
          <img src="/logos/main_logo.png" alt="Fresh Chops Logo" className="w-12 h-12" /> {/* Your Logo */}
          <span className="ml-3 text-lg font-bold text-gray-700">Green Cuts</span>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-8 text-gray-600 font-medium">
          <li><a href="/" className="hover:text-green-500">Home</a></li>
          <li><a href="products" className="hover:text-green-500">Products</a></li>
          <li><a href="about" className="hover:text-green-500">About</a></li>
          <li><a href="contact" className="hover:text-green-500">Contact</a></li>
          <li><a href="community" className="hover:text-green-500">Community</a></li>
        </ul>

        {/* Mobile Menu Icon */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 focus:outline-none"
          >
            {isOpen ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="md:hidden bg-white shadow-md">
          <li className="border-b"><a href="/" className="block p-4 text-gray-600 hover:bg-gray-100">Home</a></li>
          <li className="border-b"><a href="products" className="block p-4 text-gray-600 hover:bg-gray-100">Products</a></li>
          <li className="border-b"><a href="about" className="block p-4 text-gray-600 hover:bg-gray-100">About</a></li>
          <li className="border-b"><a href="contact" className="block p-4 text-gray-600 hover:bg-gray-100">Contact</a></li>
          <li><a href="community" className="block p-4 text-gray-600 hover:bg-gray-100">Community</a></li>
        </ul>
      )}
    </nav>
  );
};

export default NavBar;
