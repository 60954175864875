import React from 'react';
import ImageSlider from '../components/ImageSlider';
import Product from '../components/Product';

const HomeScreen: React.FC = () => {
  const topSellingProducts = [
    {
      id: 1,
      name: 'Chopped Carrots',
      image: 'https://picsum.photos/200/311',
      price: 50,
      quantity: '500g',
    },
    {
      id: 2,
      name: 'Diced Onions',
      image: 'https://picsum.photos/200/312',
      price: 40,
      quantity: '500g',
    },
    {
      id: 3,
      name: 'Shredded Cabbage',
      image: 'https://picsum.photos/200/313',
      price: 30,
      quantity: '500g',
    },
    {
      id: 4,
      name: 'Sliced Bell Peppers',
      image: 'https://picsum.photos/200/314',
      price: 35,
      quantity: '300g',
    },
    {
      id: 5,
      name: 'Chopped Spinach',
      image: 'https://picsum.photos/200/315',
      price: 25,
      quantity: '250g',
    },
  ];

  return (
    <div>
      {/* Hero Section with Image Slider */}
      <ImageSlider />

      {/* Top Selling Products Section */}
      <section className="bg-gray-50 py-10 px-4">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-2 text-green-900">Top Selling Products</h2>
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5">
            {topSellingProducts.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
        </div>
      </section>
       {/* Top Selling Products Section */}
       <section className="bg-gray-50 py-10 px-4">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-2 text-green-900">Top Nutient Products</h2>
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5">
            {topSellingProducts.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeScreen;
