import React from 'react';
import Product from '../components/Product'; // Assuming Product is in the same directory

const ProductScreen: React.FC = () => {
  const products = [
    {
      id: 1,
      name: 'Chopped Carrots',
      image: 'https://picsum.photos/200/301', // Slightly different
      price: 50,
      quantity: '500g',
    },
    {
      id: 2,
      name: 'Diced Onions',
      image: 'https://picsum.photos/200/302', // Slightly different
      price: 40,
      quantity: '500g',
    },
    {
      id: 3,
      name: 'Shredded Cabbage',
      image: 'https://picsum.photos/200/303', // Slightly different
      price: 30,
      quantity: '500g',
    },
    {
      id: 4,
      name: 'Sliced Bell Peppers',
      image: 'https://picsum.photos/200/304', // Slightly different
      price: 35,
      quantity: '300g',
    },
    {
      id: 5,
      name: 'Chopped Spinach',
      image: 'https://picsum.photos/200/305', // Slightly different
      price: 25,
      quantity: '250g',
    },
    {
      id: 6,
      name: 'Shredded Lettuce',
      image: 'https://picsum.photos/200/306', // Slightly different
      price: 28,
      quantity: '200g',
    },
    {
      id: 7,
      name: 'Chopped Broccoli',
      image: 'https://picsum.photos/200/307', // Slightly different
      price: 45,
      quantity: '300g',
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-4">
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5 container mx-auto">
        {products.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductScreen;
