import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/homeScreen';
import ContactScreen from './screens/contactScreen';
import AboutScreen from './screens/aboutScreen';
import CommunityScreen from './screens/communityScreen';
import ProductScreen from './screens/productScreen';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      {/* defining routes here */}
      <BrowserRouter>
      <NavBar/>
      <div className="pt-[3rem]"> {/* Add padding to avoid overlapping */}
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/community" element={<CommunityScreen />} />
            <Route path="/products" element={<ProductScreen />} />
          </Routes>
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
