import React from 'react';
import { FaSmile, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const CommunityScreen = () => {
  const testimonials = [
    {
      name: 'John Doe',
      feedback:
        'Green Cuts has transformed my cooking routine! The vegetables are always fresh, and I save so much time. Highly recommend it to everyone!',
      location: 'New York, USA',
    },
    {
      name: 'Jane Smith',
      feedback:
        'The delivery is lightning fast, and the veggies are chopped perfectly every time. A great service for busy families!',
      location: 'Los Angeles, USA',
    },
    {
      name: 'David Wilson',
      feedback:
        'I’m impressed with the quality and eco-friendly packaging. It feels good to support a business that cares about sustainability.',
      location: 'Chicago, USA',
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen text-gray-700">
      {/* Hero Section */}
      <section className="bg-green-500 text-white py-16">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-4xl font-semibold mb-4 sm:text-3xl md:text-4xl">Our Happy Community</h1>
          <p className="text-lg max-w-3xl mx-auto sm:text-base">
            At Green Cuts, we take pride in the smiles and satisfaction of our community. Here's what our customers have to say about us.
          </p>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="container mx-auto py-16 px-4 md:px-0">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-semibold mb-4 sm:text-2xl md:text-3xl">Happy Stories</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto sm:text-base">
            Discover why thousands trust Green Cuts for fresh, healthy, and time-saving vegetable delivery.
          </p>
        </div>

        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center"
            >
              <FaQuoteLeft className="text-green-500 text-3xl mb-4" />
              <p className="text-lg text-gray-600 italic">{testimonial.feedback}</p>
              <FaQuoteRight className="text-green-500 text-3xl mt-4" />
              <h3 className="text-xl font-semibold mt-6">{testimonial.name}</h3>
              <p className="text-sm text-gray-500">{testimonial.location}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-green-500 text-white py-16 text-center px-4 mb-1">
        <h2 className="text-3xl font-semibold mb-4 sm:text-2xl md:text-3xl">Join Our Community</h2>
        <p className="text-lg mb-6 sm:text-base">
          Be a part of a growing family that values freshness, health, and sustainability. Experience the difference today!
        </p>
        <a
          href="/about"
          className="bg-white text-green-500 font-semibold py-2 px-6 rounded-lg hover:bg-gray-200"
        >
          Learn More About Us
        </a>
      </section>
    </div>
  );
};

export default CommunityScreen;
