import React from 'react';
import { FaWhatsapp, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactScreen = () => {
  const whatsappNumber = "+919346343940";
  const whatsappMessage = "Hi, I would like to place an order for chopped vegetables.";

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center">
      {/* Hero Section */}
      <div className="w-full bg-green-500 text-white py-12 px-4 text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Order Fresh Chopped Vegetables</h1>
        <p className="text-lg md:text-xl font-light">
          Convenient, fast, and fresh. Get chopped vegetables delivered to your doorstep.
        </p>
        <a
  href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
  target="_blank"
  rel="noopener noreferrer"
  className="mt-4 inline-flex items-center bg-white text-green-500 font-semibold py-2 px-6 rounded-lg hover:bg-gray-200"
>
  <FaWhatsapp className="mr-2 text-xl" />
  Order on WhatsApp
</a>
      </div>

      {/* Contact Details */}
      <div className="container mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Contact Information */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-green-500 mb-4">Contact Us</h2>
          <ul className="space-y-4">
            <li className="flex items-center">
              <FaWhatsapp className="text-green-500 text-2xl mr-4" />
              <a
                href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-800 hover:text-green-500 text-lg"
              >
                Chat with us on WhatsApp
              </a>
            </li>
            <li className="flex items-center">
              <FaPhone className="text-green-500 text-2xl mr-4" />
              <span className="text-gray-800 text-lg">+91 9346343940</span>
            </li>
            <li className="flex items-center">
              <FaEnvelope className="text-green-500 text-2xl mr-4" />
              <span className="text-gray-800 text-lg">contact@greencuts.in</span>
            </li>
            <li className="flex items-center">
              <FaMapMarkerAlt className="text-green-500 text-2xl mr-4" />
              <span className="text-gray-800 text-lg">
                Nacharam, Hyderabad City
              </span>
            </li>
          </ul>
        </div>

        {/* Embedded Google Map */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-green-500 mb-4">Our Location</h2>
          <iframe
            title="Our Location"
            className="w-full h-64 rounded-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.7320054568413!2d78.55169387587967!3d17.429126485151993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb911dab7dfeef%3A0xa02eb95d7955a616!2sNacharam%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1696247477718!5m2!1sen!2sin"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-green-500 text-white py-8 px-4 text-center mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold">Place Your Order Now!</h2>
        <p className="mt-2 text-lg">Click below to order fresh vegetables via WhatsApp.</p>
                <a
        href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 inline-flex items-center bg-white text-green-500 font-semibold py-2 px-6 rounded-lg hover:bg-gray-200"
        >
        <FaWhatsapp className="mr-2 text-xl" />
        Order on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default ContactScreen;
